import React from 'react';

import { Container } from './styles';

export default function Location() {
  return (
    <Container id="ubicacion">
      <h2>Estamos ubicados en</h2>
      <div className="description">
        Vísitanos en nuestras instalaciones
      </div>
      <iframe
        title="map"
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/view?key=AIzaSyBACGcihEztgrZQz-y-mgX61gyShBjtNYg&center=24.80047392604765,-107.3977312725072&zoom=21&maptype=roadmap">
      </iframe>
    </Container>
  );
}
