import React from 'react';

import { Container } from './styles';

export default function Team() {
  return (
    <Container id="equipo">
      <div className="content">
        <h2>Equipo Gofisiomx</h2>
        <div className="description">
          Conoce a nuestro personal capacitado que está listo para atenderte
        </div>
        <div className="team">
          {/*<div className="item">
            <img src="./images/team/alejandra.png" alt="Alejandra Salazar" />
            <div className="name">
              LFT. Alejandra Salazar
            </div>
          </div>*/}
          <div className="item">
            <img src="./images/team/pablo.png" alt="Pablo Sánchez" />
            <div className="name">
              LFT. Pablo Sánchez
            </div>
          </div>
          <div className="item">
            <img src="./images/team/melissa.png" alt="Melissa Ayala" />
            <div className="name">
              LFT. Melissa Ayala
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
