import React from 'react';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';
import { Link } from 'gatsby';

import { Container, New } from './styles';

export default function News({ news, hideButton }) {
  return (
    <Container>
      <h2>Recomendaciones para cuidar tu cuerpo</h2>
      <div className="description">
        Te compartimos temas importantes que pueden ayudarte en tu vida diaria
      </div>
      <div className="big-square">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/DJS_1EfFjoM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div className="cards">
        {news.map(_new => (
          <Link to={`/blog${get(_new, 'node.fields.slug', '')}`}>
            <New key={get(_new, 'node.fields.slug', '')}>
              <img
                src={get(_new, 'node.frontmatter.image.publicURL', '')}
                alt={get(_new, 'node.frontmatter.title', '')}
                className="picture"
              />
              <h3>{get(_new, 'node.frontmatter.title', '')}</h3>
              {/*
                <div className="text">
                  {get(_new, 'node.frontmatter.description', '')}
                </div>
                <div className="button-container">
                    <Button color="primary">Ver más</Button>
                </div>
                */}
            </New>
          </Link>
        ))}
      </div>
      {!hideButton && (
        <div className="center">
          <Link to="/blog">
            <Button variant="outlined" color="primary">
              Conoce más temas
            </Button>
          </Link>
        </div>
      )}
    </Container>
  );
}
