import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Container } from './styles';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
};


export default function ServiceDetail({ service, open, onClose }) {
  const [title, body, images] = (() => {
    switch (service) {
        case 'sesion':
          return [
            'Sesión de fisioterapia',
            `
              <p>
                Elimina tu dolor y regresa la movilidad a tu cuerpo a través de distintas técnicas como: Electroterápia, Ultrasonido terapéutico, Láser terapéutico, masaje, terapia manual, ejercicio funcional, entre otras.
              </p>
            `,
            [
              './galerias/terapia/1.jpg',
              './galerias/terapia/2.jpg',
              './galerias/terapia/3.jpg',
              './galerias/terapia/4.jpg',
              './galerias/terapia/5.jpg',
              './galerias/terapia/6.jpg',
              './galerias/terapia/7.jpg',
              './galerias/terapia/8.jpg',
              './galerias/terapia/9.jpg',
              './galerias/terapia/10.jpg',
              './galerias/terapia/11.jpg',
              './galerias/terapia/12.jpg',
              './galerias/terapia/13.jpg',
              './galerias/terapia/14.jpg',
              './galerias/terapia/15.jpg',
              './galerias/terapia/16.jpg',
            ],
          ];
      case 'respiratoria':
        return [
          'Fisioterapia respiratoria',
          `
            <p>
              Con la terapia respiratoria, nos enfocamos en facilitar la eliminación de secreciones, aumentar la capacidad pulmonar y evitar posibles complicaciones en pacientes con patologías pulmonares.
            </p>
            <p>
              Buscamos controlar el trabajo respiratorio, a través de la disminución de las resistencias bronquiales y la enseñanza de ejercicios.
            </p>
          `,
          [
            './galerias/respiratoria/1.jpg',
            './galerias/respiratoria/2.jpg',
            './galerias/respiratoria/3.jpg',
            './galerias/respiratoria/4.jpg',
            './galerias/respiratoria/5.jpg',
            './galerias/respiratoria/6.jpg',
            './galerias/respiratoria/7.jpg',
            './galerias/respiratoria/8.jpg',
            './galerias/respiratoria/9.jpg',
            './galerias/respiratoria/10.jpg',
            './galerias/respiratoria/11.jpg',
            './galerias/respiratoria/12.jpg',
          ],
        ];
      case 'embarazadas':
        return [
          'Terapia para embarazadas',
          `
            <p>
              Buscamos desarrollar un embarazo saludable, manteniendo la flexibilidad pélvica y fortalecimiento adecuado de la musculatura lumbo-abdominal, para evitar complicaciones antes, durante y después del parto.
            </p>
            <p>
              El embarazo no deberia ser una etapa de dolor.
            </p>
          `,
          [
            './galerias/embarazadas/1.jpg',
            './galerias/embarazadas/2.jpg',
            './galerias/embarazadas/3.jpg',
            './galerias/embarazadas/4.jpg',
            './galerias/embarazadas/5.jpg',
            './galerias/embarazadas/6.jpg',
            './galerias/embarazadas/7.jpg',
            './galerias/embarazadas/8.jpg',
            './galerias/embarazadas/9.jpg',
            './galerias/embarazadas/10.jpg',
            './galerias/embarazadas/11.jpg',
            './galerias/embarazadas/12.jpg',
            './galerias/embarazadas/13.jpg',
            './galerias/embarazadas/14.jpg',
            './galerias/embarazadas/15.jpg',
            './galerias/embarazadas/16.jpg',
            './galerias/embarazadas/17.jpg',
            './galerias/embarazadas/18.jpg',
            './galerias/embarazadas/19.jpg',
          ],
        ];
      case 'masaje':
        return [
          'Masaje completo',
          `
            <p>
              Masaje completo en todo el cuerpo; cuello, espalda, brazos, manos, piernas y pie, es exclusivo puro trabajo manual y aplicación de pistola de percusión al final.
            </p>
          `,
          [
            './galerias/masaje/1.jpg',
            './galerias/masaje/2.jpg',
            './galerias/masaje/3.jpg',
            './galerias/masaje/4.jpg',
            './galerias/masaje/5.jpg',
            './galerias/masaje/6.jpg',
            './galerias/masaje/7.jpg',
            './galerias/masaje/8.jpg',
            './galerias/masaje/9.jpg',
          ],
        ];
      case 'descarga':
        return [
          'Descarga muscular',
          `
            <p>
              Terapia manual intensa en la cual se aplican compresiones, fricciones y presiones en los músculos en la que buscamos liberar la tensión y así mantener una función óptima del deportista y evitar lesiones futuras.
            </p>
            <p>
              <div>
                <b>
                  El servicio incluye
                </b>
              </div>
              <div>-Compresas calientes</div>
              <div>-Electroterápia / ventosas / presoterapia</div>
              <div>-Masaje en grupo muscular a tratar</div>
              <div>-Pistola de percusión</div>
            </p>
          `,
          [
            './galerias/descargas/1.jpg',
            './galerias/descargas/2.jpg',
            './galerias/descargas/3.jpg',
            './galerias/descargas/4.jpg',
          ],
        ];
      case 'masaje-premium':
      default:
        return [
          'Masaje premium',
          `
            <p>
              Masaje completo en todo el cuerpo; cuello, espalda, brazos, manos, piernas y pie, es exclusivo puro trabajo manual, aplicación de pistola de percusión y ajustes articulares en cuello, espalda alta y baja.
            </p>
          `,
          [
            './galerias/masaje-premium/1.jpg',
            './galerias/masaje-premium/2.jpg',
            './galerias/masaje-premium/3.jpg',
            './galerias/masaje-premium/4.jpg',
            './galerias/masaje-premium/5.jpg',
            './galerias/masaje-premium/6.jpg',
            './galerias/masaje-premium/7.jpg',
            './galerias/masaje-premium/8.jpg',
          ],
        ];
    }
  })();
  if (!open) return <div />
  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <Container>
        <IconButton className="close-icon" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className="slider-container">
          <Slider {...settings} autoplay={false}>
            {images.map((image) => (
              <img src={image} alt="" className="active-image" />
            ))}
          </Slider>
        </div>
        <div className="content">
          <div className="title">
            {title}
          </div>
          <div className="body" dangerouslySetInnerHTML={{__html: body}}></div>
        </div>
      </Container>
    </Dialog>
  );
}
