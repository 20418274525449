import React, { useMemo } from 'react';

import { Container } from './styles';

export default function ServiceItem({ service, onClick }) {
  const [image, title, description, inPlace, outPlace, time] = useMemo(() => {
    switch (service) {
      case 'sesion':
        return [
          './images/thumbnails/terapia.png',
          'Sesión de fisioterapia',
          'Mejora la calidad de vida, disminuye el dolor y mejora la movilidad.',
          499,
          499,
          '1 hr',
        ];
      case 'respiratoria':
        return [
          './images/thumbnails/respiratoria.png',
          'Fisioterapia respiratoria',
          'Aumenta la tolerancia al ejercicio y la fatiga reeducando y fortaleciendo el patrón de la respiración.',
          499,
          499,
          '1 hr',
        ];
      case 'embarazadas':
        return [
          './images/thumbnails/embarazada.jpg',
          'Terapia para embarazadas',
          'Disminuye el dolor que genera el cambio físico del embarazo, mejora la calidad del parto y previene secuelas post parto.',
          499,
          499,
          '1 hr',
        ];
      case 'masaje':
        return [
          './images/thumbnails/masaje.png',
          'Masaje completo',
          'Regálale un descanso a tu cuerpo. Olvídate del estrés disminuye tensiones, lleva tu cuerpo a un estado de rejalamiento total.',
          549,
          749,
          '1 hr',
        ];
      case 'descarga':
        return [
          './images/thumbnails/descarga.png',
          'Descarga muscular',
          'Descansa tus músculos, evita lesiones y mejora tu rendimiento deportivo.',
          399,
          499,
          '1 hr',
        ];
      case 'masaje-premium':
      default:
        return [
          './images/thumbnails/masaje-premium.jpg',
          'Masaje premium',
          'Comienza de nuevo, relaja el cuerpo y disminuye tensiones a través de un masaje completo y ajustes articulares.',
          749,
          899,
          '1 hr 15 min',
        ];

    }
  }, [service]);

  return (
    <Container onClick={onClick}>
      <img src={image} />
      <div className="title">{title}</div>
      <div className="description-item">{description}</div>
      <div className="cost">
        {`Clínica: $${inPlace} Domicilio: $${outPlace}`}
      </div>
      <div className="time">
        {`Tiempo aproximado: ${time}`}
      </div>
    </Container>
  );
}
