import styled from 'styled-components';
import { mediaQuerySmall, mediaQueryMedium } from '../../helper';

export const Container = styled.div`
  padding: 56px 24px;
  max-width: 1328px;
  margin: 0 auto;

  & h2 {
    margin: 0 0 24px;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
  }

  & .description {
    text-align: center;
    margin-bottom: 40px;
    font-size: 18px;
  }

  & .services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 980px;
    margin: 0 auto;
  }

  & .services > div:nth-child(-n + 3) {
    margin-bottom: 32px;
  }

  ${mediaQueryMedium} {
    & h2 {
      font-size: 30px;
    }

    & .description {
      font-size: 14px;
    }

    & .services {
      max-width: 660px;
    }
  }

  @media (max-width: 664px) {
    & .services {
      max-width: 100%;
      flex-direction: column;
      align-items: center;
    }

    & .services > div {
      margin-bottom: 24px;
    }
  }
`;
