import styled from 'styled-components';
import { mediaQuerySmall, mediaQueryMedium } from '../../helper';

export const Container = styled.div`
  padding: 56px 24px 56px;
  max-width: 1028px;
  margin: 0 auto;

  & h2 {
    text-align: center;
    margin: 0 0 16px;
    font-size: 36px;
    font-weight: 500;
  }

  & .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  & .description {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 48px;
  }

  & .center {
    display: flex;
    justify-content: center;
  }

  & .big-square {
    width: 100%;
    height: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 64px;
  }

  ${mediaQueryMedium} {
    & h2 {
      font-size: 30px;
    }

    & .description {
      font-size: 14px;
    }

    & .cards > *:last-child {
      display: none;
    }

    & .cards {
      max-width: 572px;
      margin: 0 auto;
    }
  }

  @media (max-width: 664px) {
    & .cards {
      flex-direction: column;
      align-items: center;
    }

    & .cards > *:last-child {
      display: unset;
    }

    & .cards > * {
      margin-bottom: 24px;
    }
  }
`;

export const New = styled.div`
  /* padding: 24px 16px 16px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(0,0,0,0.1) 0px 2px 4px 0px; */
  width: 270px;
  color: black;

  & .picture {
    width: 100%;
    height: 270px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  & h3 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  & .text {
    font-size: 14px;
    text-align: justify;
    flex-grow: 1;
    margin-bottom: 16px;

  }

  & .button-container {
    display: flex;
    justify-content: center;
    display: none;
  }

  ${mediaQuerySmall} {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`;
