import styled from 'styled-components';
import { mediaQueryXSmall, mediaQuerySmall, mediaQueryMedium } from '../../helper';

export const Container = styled.div`
  background-color: #dcf3fb;
  padding: 56px 24px;

  & .content {
    max-width: 840px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h2 {
    margin: 0 0 24px;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
  }

  & .description {
    text-align: center;
    margin-bottom: 40px;
    font-size: 18px;
  }

  & .squares {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & .squares .item {
    width: 156px;
    height: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(197,195,195,0.50);
  }

  & .squares .item > div {
    margin-top: 16px;
    font-size: 12px;
  }

  & .big-square {
    width: 100%;
    height: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 24px;
  }

  ${mediaQueryMedium} {
    & h2 {
      font-size: 30px;
    }

    & .description {
      font-size: 14px;
    }

    & .squares {
      justify-content: space-between;
    }

    & .squares .item {
      margin: 0 4px;
    }
  }

  ${mediaQuerySmall} {
    & .squares .item:not(:last-child) {
      margin-bottom: 24px;
    }

    & .squares {
      max-width: 330px;
      margin: 0 auto;
    }
  }

  ${mediaQueryXSmall} {
    & .squares {
      justify-content: center;
    }
  }
`;
