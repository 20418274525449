import styled from 'styled-components';
import { mediaQueryMedium, mediaQuerySmall } from '../../helper';

export const Container = styled.div`
  background-image: url('./images/background-desktop.png');
  min-height: 500px;
  max-width: 1328px;
  margin: 0 auto;
  padding: 32px 24px 80px;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: right;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 574px;
    font-size: 14px;
    margin-bottom: 40px;
  }

  & .topbar .logo {
    width: 124px;
    margin-right: 32px
  }

  & .topbar .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 372px;
  }

  & .topbar .basic-link {
    color: #34527d;
  }

  & .action-button {
    padding: 12px 32px;
    border-radius: 4px;
    background-color: #eb972a;
    color: #ffffff;
  }

  & .description {
    width: 560px;
    max-width: 50%;
  }

  & .description h1 {
    color: #34527d;
    margin: 0 0 24px;
    font-weight: 500;
  }

  & .description .desc {
    color: #34527d;
    font-size: 18px;
    margin-bottom: 40px;
  }

  & .background-img {
    display: none;
  }

  & .responsive-hide {
    display: unset;
  }

  & .responsive-show {
    display: none;
  }

  ${mediaQueryMedium} {
    background-image: none;
    align-items: center;
    text-align: center;
    min-height: 0;
    padding: 32px 24px 32px;

    & .topbar {
      width: 100%;
    }

    & .description {
      max-width: 100%;
      width: 100%;
    }

    & .background-img {
      width: 100%;
      margin-top: 40px;
      display: block;
    }

    & .description h1 {
      font-size: 34px;
    }

    & .description .desc {
      font-size: 16px;
    }

    & .responsive-hide {
      display: none;
    }

    & .links {
      display: none !important;
    }

    & .responsive-show {
      display: unset;
    }
  }

  ${mediaQuerySmall} {
    & .description h1 {
      font-size: 30px;
    }

    & .description .desc {
      font-size: 14px;
    }
  }
`;

export const MobileContainer = styled.div`
  background: linear-gradient(90deg, #51c4ea 50%, #dcf2fb 50%);
`;

export const ImageMobile = styled.img`
  display: none;

  ${mediaQueryMedium} {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 16px;
    display: block;
  }
`;
