import React from 'react';
import ServiceItem from '../ServiceItem';

import { Container } from './styles';

export default function Services({ news, onServiceClicked }) {
  return (
    <Container id="servicios">
      <h2>Servicios</h2>
      <div className="description">
        Conoce los servicios que tenemos disponibles para ti
      </div>
      <div className="services">
        <ServiceItem
          service="sesion"
          onClick={() => onServiceClicked('sesion')}
        />
        <ServiceItem
          service="descarga"
          onClick={() => onServiceClicked('descarga')}
        />
        <ServiceItem
          service="respiratoria"
          onClick={() => onServiceClicked('respiratoria')}
        />
        <ServiceItem
          service="embarazadas"
          onClick={() => onServiceClicked('embarazadas')}
        />
        <ServiceItem
          service="masaje"
          onClick={() => onServiceClicked('masaje')}
        />
        <ServiceItem
          service="masaje-premium"
          onClick={() => onServiceClicked('masaje-premium')}
        />
      </div>
    </Container>
  );
}
