import React from 'react';
import { Link } from 'gatsby';

import { Container, ImageMobile, MobileContainer } from './styles';

export default function MainBanner({ news }) {
  return (
    <React.Fragment>
      <Container>
        <div className="topbar">
          <Link to="/">
            <img className="logo" src="./gofisio-logo.png" alt="Gofisiomx logo" />
          </Link>
          <div className="links">
            <Link to="#servicios" className="basic-link">
              Servicios
            </Link>
            <Link to="/blog" className="basic-link">
              Blog
            </Link>
            <Link to="https://www.instagram.com/gofisioshopmx/" className="basic-link">
              GofisioShop
            </Link>
            <a className="action-button" href="tel:6671620294">
              Llama ya
            </a>
          </div>
          <Link to="/blog" className="action-button responsive-show">
            Blog
          </Link>
        </div>
        <div className="description">
          <h1>La <b>fisioterapia</b> es mucho más que un masaje</h1>
          <div className="desc">
            Es de gran importancia atender y cuidar nuestro cuerpo. Atiende tanto tus lesiones recientes como pasadas
          </div>
          <a className="action-button" href="tel:6671620294">
            Llama ya
          </a>
        </div>
      </Container>
      <MobileContainer>
        <ImageMobile src="./images/background-mobile.png" className="background-img" alt="Pablo Gofiosiomx" />
      </MobileContainer>
    </React.Fragment>
  );
}
