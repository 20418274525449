import styled from 'styled-components';
import { mediaQuerySmall, mediaQueryMedium } from '../../helper';

export const Container = styled.div`
  padding: 56px 24px;
  background-color: #fdf5eb;

  & .content {
    max-width: 1328px;
    margin: 0 auto;
  }

  & h2 {
    margin: 0 0 24px;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
  }

  & .description {
    text-align: center;
    margin-bottom: 40px;
    font-size: 18px;
  }

  & .team {
    display: flex;
    justify-content: center;
  }

  & .team .item {
    padding: 40px 56px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #eb972a;
  }

  & .team .item img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }

  & .team .item:not(:last-child) {
    margin-right: 40px;
  }

  & .team .item .name {
    color: #000000;
    margin-top: 32px;
  }

  ${mediaQueryMedium} {
    & h2 {
      font-size: 30px;
    }

    & .description {
      font-size: 14px;
    }

    & .team .item {
      padding: 40px;
    }
  }

  @media (max-width: 664px) {
    & .team {
      flex-direction: column;
      align-items: center;
    }

    & .team .item {
      margin: 0 0 24px;
    }

    & .team .item:not(:last-child) {
      margin-right: 0;
    }
  }
`;
