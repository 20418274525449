import React from 'react';

import { Container } from './styles';

export default function Masters({ news }) {
  return (
    <Container id="especialidades">
      <div className="content">
        <h2>Especialidades</h2>
        <div className="description">
          Es de gran importancia atender y cuidar nuestro cuerpo, tanto las lesiones recientes como pasadas
        </div>
        <div className="pictures-grid">
          <div className="squares">
            <div className="item">
              <img src="./especialidades/terapia-rehabilitacion.svg" />
              <div>Terapia y rehabilitación</div>
            </div>
            <div className="item">
              <img src="./especialidades/fifa.svg" />
              <div>Diplomado FIFA</div>
            </div>
            <div className="item">
              <img src="./especialidades/osteopatia.svg" />
              <div>Osteopatía</div>
            </div>
            <div className="item">
              <img src="./especialidades/respiratoria.svg" />
              <div>Fisioterapia respiratoria</div>
            </div>
          </div>
          {false && <div className="big-square">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/NLwDS3veHtw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>}
        </div>
      </div>
    </Container>
  );
}
