import styled from 'styled-components';
import { mediaQuerySmall, mediaQueryMedium } from '../../helper';

export const Container = styled.div`
  overflow: auto;
  position: relative;

  & .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
    background: #ffffff;
  }

  & .content {
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 900px;
    overflow: hidden;
  }

  & .slider-container {
    height: 600px;
    overflow: hidden;
  }

  & .slider-container img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  & .content .title {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 2px solid #F89400;
    margin-bottom: 32px;
  }

  & .content p:not(:last-child) {
    margin-bottom: 24px;
  }

  & .content .body {
    width: 100%;
  }

  & .slick-prev {
    left: 16px !important;
    z-index: 999;
  }

  & .slick-next {
    right: 16px !important;
    z-index: 999;
  }

  ${mediaQuerySmall} {
    & .slider-container {
      height: 320px;
    }

    & .slider-container img {
      height: 320px;
    }

    & .content {
      padding: 24px;
    }

    & .content .title {
      font-size: 18px;
    }

    & .content .body {
      font-size: 14px;
    }
  }
`;
