import styled from 'styled-components';
import { mediaQuerySmall, mediaQueryMedium, mediaQueryXSmall } from '../../helper';

export const Container = styled.div`
  padding: 32px 206px 32px 92px;
  background-color: #51c4ea;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  & a {
    margin-left: 40px;
    flex-shrink: 0;
    border: none;
    background-image: radial-gradient(0% 50%, #F7BB6C 0%, #F49A25 100%, #F49A25 100%);
    border-radius: 8px;
    padding: 8px 32px;
    cursor: pointer;
    transition: opacity 0.25s;
    display: flex;
    align-items: center;
    color: #000000;
    z-index: 1;
  }

  & a:hover {
    opacity: 0.8;
  }

  & a img {
    width: 24px;
    margin-right: 12px;
  }

  & .content {
    max-width: 1328px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 76px;
  }

  & .content > div {
    font-size: 24px;
    z-index: 1;
  }

  & .background1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 84px;
  }

  & .background2 {
    position: absolute;
    right: 0;
    top: 0;
  }

  ${mediaQueryMedium} {
    padding: 32px 92px;

    & .content > div {
      font-size: 20px;
    }
  }

  ${mediaQuerySmall} {
    & .content {
      flex-direction: column;
      text-align: center;
    }

    & .content > div {
      margin-bottom: 24px;
      text-shadow: 1px 1px 2px #00000021;
    }

    & a {
      margin: 0;
    }

    & .background1 {
      left: -32px;
    }

    & .background2 {
      right: -166px;
    }
  }

  ${mediaQueryXSmall} {
    padding: 32px;
  }
`;
