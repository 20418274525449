/**
 * HomePage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import Layout from '../components/MainLayout';
import News from '../components/News';
import MainBanner from '../components/MainBanner';
import Masters from '../components/Masters';
import Services from '../components/Services';
import Team from '../components/Team';
import Location from '../components/Location';
import CallToAction from '../components/CallToAction';
import ServiceDetail from '../components/ServiceDetail';

const Container = styled.div`
`;

export default function Home(props) {
  const [serviceSelected, setServiceSelected] = useState(null);
  return (
    <Layout hideTopbar>
      <Container>
        <MainBanner />
        <Masters />
        <Services onServiceClicked={value => setServiceSelected(value)} />
        <CallToAction isShop={false} />
        <News news={get(props, 'data.allMdx.edges', []) || []} />
        <Team />
        <Location />
        <CallToAction isShop />
        <ServiceDetail
          open={Boolean(serviceSelected)}
          onClose={() => setServiceSelected(null)}
          service={serviceSelected}
        />
      </Container>
    </Layout>
  );
};


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: {fields: [frontmatter___date], order: DESC}, limit: 3) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
