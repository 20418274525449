import styled from 'styled-components';
import { mediaQueryMedium } from '../../helper';

export const Container = styled.div`
  padding: 56px 24px;
  max-width: 1328px;
  margin: 0 auto;

  & h2 {
    margin: 0 0 24px;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
  }

  & .description {
    text-align: center;
    margin-bottom: 40px;
    font-size: 18px;
  }

  & iframe {
    width: 100%;
    min-height: 500px;
    height: 60vh;
    max-height: 90vh;
  }

  ${mediaQueryMedium} {
    & h2 {
      font-size: 30px;
    }

    & .description {
      font-size: 14px;
    }
  }
`;
