import styled from 'styled-components';
import { mediaQuerySmall } from '../../helper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 286px;
  max-width: 100%;
  line-height: 1.5;
  text-align: left;
  cursor: pointer;
  transition: 0.25s opacity;

  & > img {
    border-radius: 16px;
    height: 212px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 16px;
  }

  & .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  & .description-item {
    font-size: 14px;
    margin-bottom: 24px;
    text-align: left;
  }

  & .cost {
    color: #eb972a;
    font-size: 12px;
    margin-bottom: 4px;
  }

  & .time {
    font-size: 10px;
  }

  &:hover {
    opacity: 0.7;
  }

  ${mediaQuerySmall} {
  }
`;
