import React from 'react';

import { Container } from './styles';

export default function CallToAction({ isShop }) {
  const text = isShop ?
    'Visita nuestra tienda en instagram y conoce los productos que tenemos para ti'
    : 'Visita nuestro perfil en Instagram y conoce nuestras promociones';
  const button = isShop ? 'GofisiomxShop' : 'Gofisiomx';
  const link = isShop ? 'https://www.instagram.com/gofisioshopmx/' : 'https://www.instagram.com/gofisiomx/';

  return (
    <Container>
      <img src="./images/cta-back1.png" className="background1" />
      <img src="./images/cta-back2.png" className="background2" />
      <div className="content">
        <div>{text}</div>
        <a href={link} target="_blank">
          <img src="./images/instagram-black.svg" />
          {button}
        </a>
      </div>
    </Container>
  );
}
